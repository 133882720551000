.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

.spinner:after {
  content: " ";
  display: block;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-top: -32px;
  margin-left: -32px;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #3e3e45;
  border-color: #3e3e45 transparent #3e3e45 transparent;
  animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinnerCard {
  position: relative;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.spinnerCard:after {
  content: "";
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  margin-top: -32px;
  margin-left: -32px;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #3e3e45;
  border-color: #3e3e45 transparent #3e3e45 transparent;
  animation: spinner 1.2s linear infinite;
}
.react-code-input input[type="number"]::-webkit-inner-spin-button,
.react-code-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-code-input input[type="number"] {
  -moz-appearance: textfield;
}

.navbar-title {
  font-size: 0.775em;
}
.dataTable {
  box-shadow: none !important;
}

.dataTableWholeHeight {
  height: 640px !important;
}
.dataTablePeriods {
  height: 420px !important;
}
.inputDate {
  border-radius: 10px;
  border-color: aliceblue;
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
/* DatePickerStyles.css */

.muiStyledPicker {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 10px;

  font-size: 16px;
  transition: border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.muiStyledPicker:focus {
  border-color: #3f51b5;
  box-shadow: 0 0 0 0.2rem rgba(63, 81, 181, 0.25);
}

.muiStyledPicker::-webkit-calendar-picker-indicator {
  background: transparent;
  color: inherit;
}

.datepicker-label {
  font-size: 0 1em;
  color: #3e3e45;
  margin-top: 20px;
  margin-left: -190%;
}

.Mui-disabled.css-etpsm3-MuiButtonBase-root-MuiButton-root {
  background-color: rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
}
.css-jjzg5p-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 50 !important;
}

.css-181t24u-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  margin-right: -7px !important;
}

.tableBody {
  height: calc(100% - 10vh);
  flex-grow: 1;
  overflow-y: auto;
}

.paginationContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: white;
}
.noDataContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #999999;
  height: 100%;
  width: 100%; /* Added width to span across all columns */
  text-align: center;
}

.noDataIcon {
  margin-right: 8px;
}
.tableFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.tableBody thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  opacity: 1;
  z-index: 1;
}
.disabledInput > div::before {
  border-bottom: none !important;
}
.disabledInput > div > input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.disabledInput {
  background-color: transparent !important;
}
.css-12kywc6-MuiFormControl-root-MuiTextField-root {
  background-color: transparent !important;
}
.errorMessageBox {
  position: absolute;
  width: 100%;
  bottom: 0;
}

table.reportTable {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  font-size: 0.6em;
}
.report-table-cont {
  overflow-x: auto !important;
  padding-left: 0px !important;
  margin-left: 24px !important;
}

table.reportTable th,
table.reportTable td {
  border: 1px solid #ddd;
  padding: 7px;
}

.cellValues {
  text-align: right;
  min-width: 90px;
}

.headerCell {
  background-color: #f1f1f1;
  font-weight: bold;
}
.column-header-cell {
  background-color: #f1f1f1;
  font-weight: bold;
  text-align: center;
}

.row-header-cell {
  background-color: #f1f1f1;
  font-weight: bold;
  text-align: left;
}

table.reportTable th {
  background-color: #e4e4e4;
  font-weight: bold;
}

table.reportTable tr:nth-child(odd) {
  background-color: #f8f9fa;
}
.inputDescription > div > input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.report-table-container {
  margin-top: 0px !important;
}

.report-table-container > div {
  padding-top: 0px !important;
}

.pl0 {
  padding-left: 0px !important;
}
.pl1 {
  padding-left: 0.5em !important;
}
.Mui-expanded {
  max-height: 30px !important;
  min-height: 30px !important;
  margin: 0 0 !important;
}

.mapping-container,
.mapping-container > div {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.node-info-cell-header {
  font-size: 0.9em !important;
  width: 30% !important;
}
.calculations-table-header {
  font-size: 1em !important;
}
.calculations-table-cell {
  font-size: 0.9em !important;
}
.node-info-cell-delete {
  font-size: 1em !important;
  width: 10% !important;
  text-align: center !important;
}
.table-header {
  font-size: 1em !important;
  text-align: center !important;
}
.node-info-cell-value {
  font-size: 1em !important;
  width: 70% !important;
}
.node-info-documentation {
  font-size: 0.7em !important;
  width: 70% !important;
}
.taxonomy-tree > div > div {
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
}
.pt0 {
  padding-top: 0px !important;
}
.mt0 {
  margin-top: 0px !important;
}
.ml0 {
  margin-left: 0px !important;
}
.pb1 {
  padding-bottom: 1vh !important;
}
.pb0 {
  padding-bottom: 0px !important;
}
.pt8 {
  padding-top: 8px !important;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.details-container {
  height: 100%;
  overflow-y: auto;
}
.details-header {
  position: sticky;
  top: 0;
  z-index: 20;
}

.selected-column {
  background-color: #261bb9; /* or whatever style you want */
}

.selected-row {
  background-color: #e5fff0 !important; /* Your preferred color */
}
.invisible-black-background {
  background-color: rgba(0, 0, 0, 0.04);
}
.highlight-column {
  background-color: rgba(0, 0, 0, 0.08);
}
.selectedColumnClass {
  background-color: #e5fff0; /* Adjust color as needed */
}
.report_cell {
  overflow: hidden;
  text-overflow: ellipsis;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaders {
  display: none;
}
.drop-menu,
.drop-menu > ul {
  z-index: 30 !important;
}
.period-select,
.period-select > option {
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  font-size: 1rem !important;
  height: 75%;
  color: #344767;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
}

.calculation-weight-select,
.calculation-weight-select > option {
  width: 80% !important;
  border: none !important;
  border-bottom: 1px solid #ced4da !important;
  font-size: 1rem !important;
  text-align: center;
  height: 100%;
  color: #344767;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
}
.extend-role-input > label {
  padding-left: 4px !important;
}
/*contextify*/
:root {
  --contexify-zIndex: 1200;
  --contexify-menu-minWidth: 220px;
  --contexify-menu-padding: 6px;
  --contexify-menu-radius: 6px;
  --contexify-menu-bgColor: #fff;
  --contexify-menu-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1), 2px 4px 4px rgba(0, 0, 0, 0.1),
    3px 6px 6px rgba(0, 0, 0, 0.1);
  --contexify-menu-negatePadding: var(--contexify-menu-padding);
  --contexify-separator-color: rgba(0, 0, 0, 0.2);
  --contexify-separator-margin: 5px;
  --contexify-itemContent-padding: 6px;
  --contexify-activeItem-radius: 4px;
  --contexify-item-color: #333;
  --contexify-activeItem-color: #fff;
  --contexify-activeItem-bgColor: #3498db;
  --contexify-rightSlot-color: #6f6e77;
  --contexify-activeRightSlot-color: #fff;
  --contexify-arrow-color: #6f6e77;
  --contexify-activeArrow-color: #fff;
}

@keyframes contexify_feedback {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.contexify {
  position: fixed;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: var(--contexify-menu-bgColor);
  box-sizing: border-box;
  box-shadow: var(--contexify-menu-shadow);
  border-radius: var(--contexify-menu-radius);
  padding: var(--contexify-menu-padding);
  min-width: var(--contexify-menu-minWidth);
  z-index: var(--contexify-zIndex);
}
.contexify_submenu-isOpen,
.contexify_submenu-isOpen > .contexify_itemContent {
  color: var(--contexify-activeItem-color);
  background-color: var(--contexify-activeItem-bgColor);
  border-radius: var(--contexify-activeItem-radius);
}
.contexify_submenu-isOpen > .contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-activeArrow-color);
}
.contexify_submenu-isOpen > .contexify_submenu {
  pointer-events: initial;
  opacity: 1;
}
.contexify .contexify_submenu {
  position: absolute;
  pointer-events: none;
  transition: opacity 0.265s;
  /* Initial submenu position */
  top: calc(-1 * var(--contexify-menu-negatePadding));
  left: 100%;
}
.contexify .contexify_submenu-bottom {
  bottom: calc(-1 * var(--contexify-menu-negatePadding));
  top: unset;
}
.contexify .contexify_submenu-right {
  right: 100%;
  left: unset;
}
.contexify_rightSlot {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  color: var(--contexify-rightSlot-color);
}
.contexify_separator {
  height: 1px;
  cursor: default;
  margin: var(--contexify-separator-margin);
  background-color: var(--contexify-separator-color);
}
.contexify_willLeave-disabled {
  pointer-events: none;
}
.contexify_item {
  cursor: pointer;
  position: relative;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.7rem;
}
.contexify_item:focus {
  outline: 0;
}
.contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent .contexify_rightSlot,
.contexify_item:focus .contexify_rightSlot {
  color: var(--contexify-activeRightSlot-color);
}
.contexify_item:not(.contexify_item-disabled)[aria-haspopup]
  > .contexify_itemContent
  .contexify_rightSlot {
  color: var(--contexify-arrow-color);
}
.contexify_item[aria-haspopup]:focus > .contexify_itemContent .contexify_rightSlot,
.contexify_item:not(.contexify_item-disabled)[aria-haspopup].contexify_submenu-isOpen
  > .contexify_itemContent
  .contexify_rightSlot,
.contexify_item:not(.contexify_item-disabled)[aria-haspopup]:hover
  > .contexify_itemContent
  .contexify_rightSlot {
  color: var(--contexify-activeArrow-color);
}
.contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent,
.contexify_item:not(.contexify_item-disabled):focus > .contexify_itemContent {
  color: var(--contexify-activeItem-color);
  background-color: var(--contexify-activeItem-bgColor);
  border-radius: var(--contexify-activeItem-radius);
}
.contexify_item:not(.contexify_item-disabled):hover > .contexify_submenu {
  pointer-events: initial;
  opacity: 1;
}
.contexify_item-disabled {
  cursor: default;
  opacity: 0.5;
}
.contexify_itemContent {
  padding: var(--contexify-itemContent-padding);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  color: var(--contexify-item-color);
  position: relative;
}
.contexify_item-feedback {
  animation: contexify_feedback 0.12s both;
}

.contexify_theme-dark {
  --contexify-menu-bgColor: rgba(40, 40, 40, 0.98);
  --contexify-separator-color: #4c4c4c;
  --contexify-item-color: #fff;
}

.contexify_theme-light {
  --contexify-separator-color: #eee;
  --contexify-item-color: #666;
  --contexify-activeItem-color: #3498db;
  --contexify-activeItem-bgColor: #e0eefd;
  --contexify-activeRightSlot-color: #3498db;
  --contexify-active-arrow-color: #3498db;
}

@keyframes contexify_scaleIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}
@keyframes contexify_scaleOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}
.contexify_willEnter-scale {
  transform-origin: top left;
  animation: contexify_scaleIn 0.3s;
}

.contexify_willLeave-scale {
  transform-origin: top left;
  animation: contexify_scaleOut 0.3s;
}

@keyframes contexify_fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes contexify_fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}
.contexify_willEnter-fade {
  animation: contexify_fadeIn 0.3s ease;
}

.contexify_willLeave-fade {
  animation: contexify_fadeOut 0.3s ease;
}

@keyframes contexify_flipInX {
  from {
    transform: perspective(800px) rotate3d(1, 0, 0, 45deg);
  }
  to {
    transform: perspective(800px);
  }
}
@keyframes contexify_flipOutX {
  from {
    transform: perspective(800px);
  }
  to {
    transform: perspective(800px) rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }
}
.contexify_willEnter-flip {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  transform-origin: top center;
  animation: contexify_flipInX 0.3s;
}

.contexify_willLeave-flip {
  transform-origin: top center;
  animation: contexify_flipOutX 0.3s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes contexify_slideIn {
  from {
    opacity: 0;
    transform: scale3d(1, 0.3, 1);
  }
  to {
    opacity: 1;
  }
}
@keyframes contexify_slideOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d(1, 0.3, 1);
  }
}
.contexify_willEnter-slide {
  transform-origin: top center;
  animation: contexify_slideIn 0.3s;
}

.contexify_willLeave-slide {
  transform-origin: top center;
  animation: contexify_slideOut 0.3s;
}

/*# sourceMappingURL=ReactContexify.css.map */
.drag-enter {
  background-color: #ecfef1 !important;
  background: fixed;
}
.drag-enter-warn {
  background-color: #eabece !important;
  background: fixed;
}
.mapped-cell {
  background-color: #d1f7db;
}
.scenario-cell {
  font-style: italic;
}

.info-table-container {
  padding-left: 1%;
  padding-bottom: 10px;
  max-width: 99%;
}
.confirm-dialog-content {
  overflow-wrap: break-word;
}
.info-dialog-content {
  overflow-wrap: break-word;
  text-align: center;
}
.info-dialog-title {
  text-align: center;
}
.info-dialog-icon {
  font-size: 150px !important;
}
.info-dialog-icon-error {
  color: #f02e2ec2 !important;
}
.info-dialog-icon-success {
  color: #18c721 !important;
}
.calculation-info-dialog > div > div {
  min-width: 30% !important;
}
.pt0.pl0 table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pt0.pl0 table {
  table-layout: fixed;
  width: 100%;
}
.validations-dialog > div > div[role="dialog"] {
  height: auto !important;
}
.validationmsg-modal {
  height: 100%;
  justify-content: center !important;
  align-items: center !important;
}
.collapsible-row > td > div {
  width: 100%;
  overflow-wrap: break-word;
}
.hover-node {
  background-color: #ecfef1 !important;
}
.drag-node-start {
  background-color: #d1f7db !important;
}
.calculation-debit {
  background-color: #f7d1d17a !important;
}
.calculation-credit {
  background-color: #d1f7db6e !important;
}
[role="treeitem"] {
  outline: #261bb9 !important;
}
.context-icon {
  width: 20% !important;
  text-align: center;
  border-right: 1px solid #5f5f6a6f;
  display: block !important;
}
.context-text {
  padding-left: 10%;
  width: 80% !important;
}
.weight-select {
  width: 60% !important;
  margin: auto !important;
}
.weight-select > div {
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
}
.weight-input > div > input {
  text-align: center !important;
}

.weight-input > .MuiInputBase-root.MuiInput-root::before {
  border: none !important;
}
.position-select {
  width: 100% !important;
  margin: auto !important;
}
.position-select > div {
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
  text-align: left !important;
}
div.position-select input:first-child {
  padding-left: 10px !important;
  text-align: left !important;
}
.calculation-cell-credit {
  border: 2px solid rgb(48, 197, 48) !important;
  padding: 5px !important;
}
.calculation-cell-debit {
  border: 2px solid rgb(247, 68, 68) !important;
}
.selected-cell {
  border: 2px solid rgb(54, 89, 226) !important;
}
.extension-container {
  padding-top: 4vh !important;
}
.extended-item {
  color: #2ca4ef !important;
}
.custom-dropdown {
  border-radius: 0.375rem !important;
  border: 1px solid #ced2d6 !important;
  padding: 2px 10px !important;
  width: 100% !important;
  text-align: left !important;
}
.p-treeselect-items-wrapper {
  height: 35vh !important;
}
.p-treeselect-panel {
  z-index: 9999 !important;
  font-size: 14px !important;
  background-color: white !important;
  overflow-y: hidden !important;
  max-height: 35vh !important;
}
.p-treenode-content {
  display: flex;
  align-items: center;
}

.p-treenode-label {
  flex-grow: 1;
}
.p-treenode-children {
  padding-left: 1em;
}
.p-treenode-children ul {
  list-style-type: none;
}
.p-treeselect-filter {
  height: 35px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}
.p-inputtext {
  padding-left: 10px !important;
}
.p-treeselect-filter-container {
  height: 45px !important;
}
.p-treeselect-filter-container > svg {
  margin-top: -8px !important;
}
.options-item {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 30px;
  font-size: 0.8em;
  white-space: nowrap;
}
.options-item > svg {
  vertical-align: middle;
  font-size: 1.1em !important;
  margin-right: 5px !important;
  margin-bottom: 2px !important;
}
.options-item > span {
  vertical-align: middle;
  padding-left: 5px;
}
.relation-select {
  width: 100% !important;
  margin: auto !important;
}
.relation-select > div {
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem !important;
}
.weight-input > div > input {
  text-align: center !important;
}
.scrollable-container {
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
}
.css-3p0new-MuiTableContainer-root {
  box-shadow: none !important;
}
.custom-filter {
  width: 100% !important;
  margin: auto !important;
  padding-left: 5px !important;
}
.p-treeselect-label {
  font-size: 0.9em !important;
  padding: 2px !important;
}
.p-treeselect-close {
  display: none !important;
}
.flatpickr-input {
  height: 15px !important;
}
.calculation-error {
  color: red;
  font-weight: 500;
  cursor: pointer;
}
.inline-viewer {
  height: 95% !important;
  width: 100% !important;
}
.nonNumeric {
  cursor: pointer;
  background-color: #04e804;
}
.add-footnote {
  cursor: pointer;
  padding-right: 2px;
}
@keyframes pulsate {
  0% {
    border-color: rgba(54, 89, 226, 0.5);
    background-color: rgba(54, 89, 226, 0.1);
  }
  50% {
    border-color: rgba(54, 89, 226, 1);
    background-color: rgba(54, 89, 226, 0.3);
  }
  100% {
    border-color: rgba(54, 89, 226, 0.5);
    background-color: rgba(54, 89, 226, 0.1);
  }
}
.highlight-node {
  border: 2px solid rgba(54, 89, 226, 0.5);
  background-color: rgba(54, 89, 226, 0.1);
  animation: pulsate 1s infinite;
}
